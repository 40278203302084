import React from 'react'
import PropTypes from 'prop-types'
import { ContentInner, ContentMain } from './index.style'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Spacer from '@components/Spacer'
import RichText from '@components/RichText'

const Content = ({ content, children }) => {
  return (
    <ContentMain>
      <Container>
        <Grid>
          <GridItem tabletP={10} tabletPStart={2} tabletL={8} tabletLStart={3}>
            <ContentInner>
              <Spacer size={[48, 72]} />

              <RichText content={content} />

              {children && (
                <>
                  <Spacer size={[24, 40]} />
                  {children}
                </>
              )}

              <Spacer size={[48, 88]} />
            </ContentInner>
          </GridItem>
        </Grid>
      </Container>
    </ContentMain>
  )
}

Content.propTypes = {
  content: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default Content
