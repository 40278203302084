import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import Content from '@components/Content'
import LinkButton from '@components/LinkButton'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const OurWorkPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
    hero,
    content,
    cta,
  } = data.contentfulAbout

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <Hero data={hero} />

      <Content content={content}>
        <AnimateSlideIn>
          <LinkButton button={cta} />
        </AnimateSlideIn>
      </Content>

      <Footer />
    </>
  )
}

export default OurWorkPage

export const pageQuery = graphql`
  query OurWorkPage($slug: String!) {
    contentfulAbout(slug: { eq: $slug }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      hero {
        ...ContentfulHero
      }
      content {
        raw
      }
      cta {
        ... on Node {
          ...ContentfulButton
        }
      }
    }
  }
`
