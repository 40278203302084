import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import styled from 'styled-components'

export const ContentMain = styled.div``

export const ContentInner = styled.div`
  ${clamp('padding-left', 24, 92)}
  ${clamp('padding-right', 24, 92)}
  background-color: ${colors.light};
`
